import React, {useEffect, useState, useHisotory} from "react";
import Toolbar from "@mui/material/Toolbar";
import {ButtonSX, TitluSX} from "../App.styles";
import {AppBar, Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import {useNavigate, useLocation} from 'react-router-dom';
import logo from '../imaginiSite/logo2.png';

const useStyles = makeStyles({
    popOverRoot: {
        pointerEvents: "none"
    }
});


export const Appbar = ({refs}) => {

    const navigate = useNavigate();

    const {acasaRef, serviciiRef, despreRef, proiecteRef, contactRef} = refs;

    const [activeSection, setActiveSection] = useState(useLocation().pathname === '/pagina-proiecte' ? null : 'Acasa'); // Acasa este highlited default si pe cealalta pagina nu se activeaza

    const scrollToRef = (ref) => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    }

    let currentlyHovering = false;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const classes = useStyles();

    function handleClick(event) {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }

    function handleHover() {
        currentlyHovering = true;
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function handleCloseHover() {
        currentlyHovering = false;
        setTimeout(() => {
            if (!currentlyHovering) {
                handleClose();
            }
        }, 10);
    }


    useEffect(() => {
        const handleScroll = () => {
            const sections = [acasaRef, serviciiRef, despreRef, proiecteRef, contactRef];
            const labels = ['Acasa', 'Servicii', 'Despre', 'Proiecte', 'Contact'];

            sections.forEach((section, index) => {
                if (section.current) {
                    const {top} = section.current.getBoundingClientRect();
                    if (top <= 64) {
                        setActiveSection(labels[index]);
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);

    }, [acasaRef, serviciiRef, despreRef, proiecteRef, contactRef]);

    const getButtonStyle = (section) => ({
        ...ButtonSX,
        color: activeSection === section ? '#00ADB5' : '#EEEEEE'
    });

    // const history = useHistory(); // pt a putea naviga pe pagina proiecte
    //
    // const navigateToSection = (ref, section, offset = 0) => {
    //     setActiveSection(section);
    //     if (window.location.pathname === '/') {
    //         window.scrollTo({
    //             top: ref.current.offsetTop - offset,
    //             behavior: 'smooth'
    //         });
    //     } else {
    //         // history.push('/');
    //         setTimeout(() => {
    //             window.scrollTo({
    //                 top: ref.current.offsetTop - offset,
    //                 behavior: 'smooth'
    //             });
    //         }, 10);
    //     }
    // }

    const navigateToSection = (ref, section, offset = 0) => {
        setActiveSection(section);

        if (window.location.pathname === '/' || window.location.hash.includes('#/')) {
            // Dacă ești deja pe pagina de start, doar faci scroll
            window.scrollTo({
                top: ref.current.offsetTop - offset,
                behavior: 'smooth'
            });
        } else {
            // Dacă ești pe altă pagină, navighează întâi la "/", apoi fă scroll la secțiune
            navigate('/');
            setTimeout(() => {
                window.scrollTo({
                    top: ref.current.offsetTop - offset,
                    behavior: 'smooth'
                });
            }, 100); // Întârziere mică pentru a asigura că pagina s-a încărcat complet înainte de scroll
        }
    };

    return <div>
        <AppBar position="fixed" sx={{backgroundColor: '#222831'}}>
            <Toolbar sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                {/*<a href={'/'} style={{textDecoration: 'none'}}>*/}
                {/*    <Typography*/}
                {/*        component="h1"*/}
                {/*        variant="h4"*/}
                {/*        color="inherit"*/}
                {/*        noWrap*/}
                {/*        sx={{...TitluSX, cursor: 'pointer'}}*/}
                {/*        onClick={() => navigateToSection(acasaRef)}*/}
                {/*    >*/}
                {/*        ALLPRO Service*/}
                {/*    </Typography>*/}
                {/*</a>*/}
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // backgroundColor: '#393E46',
                    height: '64px',
                    width: '360px',
                    marginLeft: '-25px',
                }}>
                    <img
                        src={logo}
                        alt="logo AllPro Service"
                        style={{width: '300px', height: '53px', cursor: 'pointer'}}
                        onClick={() => navigateToSection(acasaRef)}
                    />
                </Box>
                {/*Logo^^^*/}
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexGrow: 1,
                    justifyContent: {xs: 'center', sm: 'flex-end'}
                }}>
                    {['Acasa', 'Servicii'].map((section) => (
                        <Button
                            key={section}
                            sx={getButtonStyle(section)}
                            onClick={() => navigateToSection(refs[section.toLowerCase() + 'Ref'], section)}
                        >
                            {section}
                        </Button>
                    ))}

                    <Button
                        key="Despre"
                        sx={getButtonStyle('Despre')}
                        onClick={() => navigateToSection(despreRef, 0, 63)} // offset ii ca sa fie Despre la dunga 63+40
                    >
                        Despre
                    </Button>

                    <Button // Hover meniu
                        key="Proiecte"
                        sx={getButtonStyle('Proiecte')}
                        aria-owns={anchorEl ? "simple-menu" : undefined}
                        aria-haspopup="true"
                        onClick={() => navigateToSection(proiecteRef)}
                        onMouseOver={handleClick}
                        onMouseLeave={handleCloseHover}
                    >
                        Proiecte
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            onMouseEnter: handleHover,
                            onMouseLeave: handleCloseHover,
                            style: {pointerEvents: "auto"}
                        }}
                        // getContentAnchorEl={null}
                        // anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                        PopoverClasses={{
                            root: classes.popOverRoot
                        }}
                        disableScrollLock={true}
                    >
                        <MenuItem onClick={() => {
                            window.location.href = '/pagina-proiecte';
                            handleClose();

                        }}>
                            <a href={'/pagina-proiecte'} style={{textDecoration: 'none', color: '#222831'}}>
                                Galerie Proiecte
                            </a>
                        </MenuItem>
                    </Menu>


                    <Button
                        key="Contact"
                        sx={getButtonStyle('Contact')}
                        onClick={() => navigateToSection(refs.contactRef)}
                    >
                        Contact
                    </Button>
                </Box>
            </Toolbar>
        </AppBar>
    </div>
}
