import React, {useRef} from 'react';
import {CssBaseline} from "@mui/material";
import {Appbar} from "./components/Appbar";
import {Acasa} from "./components/Acasa";
import {Servicii} from "./components/Servicii";
import {Despre} from "./components/Despre";
import CompanyStats from "./components/CompanyStats";
import {Proiecte} from "./components/Proiecte";
import {Contact} from "./components/Contact";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'; // Update to Routes from Switch
import PaginaProiecte from './components/PaginaProiecte';
import {TermeniSiConditii} from "./components/TermeniSiConditii";
import ScrollToTop from "./components/ScrollToTop"; // Import your new component
// import { HashRouter as Router, Route, Routes } from 'react-router-dom';

export const App = () => {
  const acasaRef = useRef(null);
  const serviciiRef = useRef(null);
  const despreRef = useRef(null);
  const proiecteRef = useRef(null);
  const contactRef = useRef(null);

  return (
      <Router>
        <div>
          <CssBaseline/>
          <ScrollToTop /> {/* Adăugăm ScrollToTop pentru a reseta scroll-ul la fiecare schimbare de rută */}
          <Appbar refs={{ acasaRef, serviciiRef, despreRef, proiecteRef, contactRef }}/>
          <Routes>
            <Route path="/" element={
              <>
                <Acasa ref={acasaRef} toRef={serviciiRef} />
                <Servicii ref={serviciiRef}/>
                <Despre ref={despreRef}/>
                {/*<CompanyStats/>*/}
                <Proiecte ref={proiecteRef}/>
                <Contact ref={contactRef} acasaRef={acasaRef}/>
              </>
            } />
            <Route path="/pagina-proiecte" element={<PaginaProiecte />} />
            <Route path="/termenisiconditii" element={<TermeniSiConditii />} />
          </Routes>
        </div>
      </Router>
  );
}
